//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    XModal: () => import("@/components/XModal"),
    progresscom: () => import("../data-analysis/progressCom"),
  },
  props: {
    //开始的日期
    stime: {
      type: String,
      default: null,
    },
    //用户id
    uid: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      pageData: {
        pageIndex: 1,
        pageSize: 20,
        totalNum: 0,
      }, //分页
      loading: false,
      tableData: [], //软件表格数据
      selRow: null, //选择的窗口
      etime: null,
    };
  },
  methods: {
    handleWindowKey(row) {
      this.selRow = {
        name: row.Key,
      };
      this.$modal.show("allfoftwarepic");
    },
    /**
     * 获取全部软件数据
     */
    getAllData() {
      const sdate = new Date(this.stime);
      this.etime = new Date(sdate.setDate(sdate.getDate() + 1)).timeFormat(
        "yyyy-MM-dd"
      );
      const data = {
        p: this.pageData.pageIndex,
        c: this.pageData.pageSize,
        stime: this.stime,
        etime: this.etime,
        u: this.uid,
      };
      this.loading = true;
      this.$http
        .get("/Company/MemberJob/AppDetails.ashx", {
          params: data,
        })
        .then((resp) => {
          if (resp.res == 0) {
            if (resp.data && resp.data.data.length) {
              //树形表格添加id
              resp.data.data.forEach((e, index) => {
                e.haschil = true;
                e.rid = index + 1;
                if (e.Form && e.Form.length) {
                  e.Form.forEach((s, ind) => {
                    s.rid = `${index + 1}-${ind}`;
                  });
                }
              });
            }
            this.tableData = resp.data.data;
            this.pageData.totalNum = resp.data.total;
          }
        })
        .finally(() => (this.loading = false));
    },
    /**
     * 每页条数改变
     */
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.pageData.pageIndex = 1;
      this.getAllData();
    },
    /**
     * 页数改变
     */
    handleCurrentChange(val) {
      this.pageData.pageIndex = val;
      this.getAllData();
    },

    attenceHeaderStyle() {
      return {
        color: "#909399",
      };
    },
  },
};
